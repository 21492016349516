import React from 'react'
import { Card, Container, Row, Col, Label, Button, FormGroup } from 'reactstrap'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import { ERRORS_DICT } from '../Manager/AppManager.interface'
import { upload } from '~/api/v2'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import Input from '~/components/forms/Input/Input'
import Select from '~/components/forms/Select'
import { useStore, withStore } from '~/dataStore'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import UploadAvatar from '~/components/UploadAvatar'
import Link from '~/components/Link'
import routes from '~/routes'
import { convertImageToUrl } from '~/utils/file.service'

import styles from './NewApplication.scss'

type FormValues = {
  name: string
  company: string
  appleCertificate: FileList
  applePassword: string
  fcmJsonSecret: FileList
  appleBundleId: string
  mode: 'production' | 'development'
  appAvatar: {
    objectURL: string
    fileName: string
    file: File | null
    valid: boolean
  }
}

const NewApplication = () => {
  const {
    setError,
    register,
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      appAvatar: {
        objectURL: '',
        fileName: '',
        file: null,
        valid: true
      }
    }
  })

  const {
    app: { currentApp }
  } = useStore()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const formData = new FormData()
    formData.append('app[setting_attributes][mode]', '') // set default for required field

    if (data.appAvatar.file) {
      try {
        const imageS3Url = await convertImageToUrl(
          currentApp.id,
          data.appAvatar.file
        )
        formData.append('app[image_s3_url]', imageS3Url)
      } catch (error) {
        if (error?.body.error) {
          setError('uploadError', { type: 'server', message: error.body.error })
        } else {
          showGeneralError()
        }
        return
      }
    }

    formData.append('app[name]', data.name)

    if (data.appleCertificate.length) {
      formData.append(
        'app[setting_attributes][apple_certificate]',
        data.appleCertificate[0]
      )
    }
    if (data.applePassword) {
      formData.append(
        'app[setting_attributes][apple_password]',
        data.applePassword
      )
    }
    if (data.fcmJsonSecret.length) {
      formData.append(
        'app[setting_attributes][fcm_json_secret]',
        data.fcmJsonSecret[0]
      )
    }
    if (data.appleBundleId) {
      formData.append(
        'app[setting_attributes][apple_bundle_id]',
        data.appleBundleId
      )
    }
    if (data.mode) formData.append('app[setting_attributes][mode]', data.mode)

    try {
      const { id } = await upload('', formData)
      window.location.href = `${window.location.origin}/mobile/apps/${id}/dashboards`
    } catch (error: Error & { body: any }) {
      if (error.body.errors) {
        try {
          getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach(
            (er) => setError(er.name, { type: 'server', message: er.message })
          )
        } catch (error) {
          showGeneralError()
        }
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <Container className="mb-4">
      <Row className="justify-content-center">
        <Col xs={{ size: 7 }}>
          <Header className="mb-3">
            <HeaderTitle>Creating a New App</HeaderTitle>
          </Header>
          <Card className="mt-4 p-3 py-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={{ size: 8 }}>
                  <Input
                    label="Name"
                    name="name"
                    placeholder="Your App Name"
                    validation={{
                      required: {
                        value: true,
                        message: 'Application name is missing'
                      }
                    }}
                    className={cn('mb-3', { 'form-group--error': errors.name })}
                    register={register}
                    errorTooltip={errors?.name?.message}
                    tooltip=""
                  />
                  <FormGroup className="w-100 mt-2 mb-0">
                    <Label className="fw-medium form-label">Mode</Label>
                    <Controller
                      control={control}
                      name="mode"
                      rules={{
                        required: {
                          value: true,
                          message: 'App mode is missing'
                        }
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onSelect={onChange}
                          selectedOption={value}
                          title="Choose Mode"
                          invalid={!!errors?.mode}
                          errorTooltip={errors?.mode?.message}
                          wrapperClassName="w-100"
                          className="justify-content-between"
                          options={[
                            { name: 'production', value: 'production' },
                            { name: 'development', value: 'development' }
                          ]}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs={{ size: 4 }} className="d-flex justify-content-center">
                  <Controller
                    control={control}
                    name="appAvatar"
                    render={({ field: { onChange, value } }) => (
                      <UploadAvatar
                        className={styles.uploadAvatar}
                        onChange={({ objectURL, fileName, file, valid }) => {
                          clearErrors('uploadError')
                          onChange({ objectURL, fileName, file, valid })
                        }}
                        image={value.objectURL}
                        fileName={value.fileName}
                        error={errors.uploadError?.message}
                      />
                    )}
                  />
                </Col>
              </Row>
              <div className="mt-2 pt-2">
                <FormGroup className="mb-0">
                  <Label className="fw-medium form-label">
                    Upload Apple Certificate
                  </Label>
                  <Input
                    name="appleCertificate"
                    register={register}
                    className={cn('mb-3', {
                      'form-group--error': errors.appleCertificate
                    })}
                    type="file"
                    accept=".pem"
                    errorTooltip={errors?.appleCertificate?.message}
                    tooltip=""
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label className="fw-medium form-label">
                    Upload FCM JSON Secret
                  </Label>
                  <Input
                    name="fcmJsonSecret"
                    register={register}
                    className={cn('mb-3', {
                      'form-group--error': errors.fcmJsonSecret
                    })}
                    type="file"
                    accept=".json"
                    errorTooltip={errors?.fcmJsonSecret?.message}
                    tooltip=""
                  />
                </FormGroup>
                <Input
                  placeholder="Your Apple Bundle ID"
                  tooltip="A bundle ID or bundle identifier uniquely identifies an application in Apple's ecosystem."
                  register={register}
                  label="Apple Bundle ID"
                  name="appleBundleId"
                  className="mb-3"
                />
              </div>
              <Input
                label="Apple Certificate Password"
                name="applePassword"
                register={register}
                className="mb-3"
                placeholder="Your Apple Certificate Password"
                tooltip="If you set a password for your cert, enter it here"
              />
              <Button
                color="primary"
                type="submit"
                className="mt-4"
                disabled={isSubmitting || !watch('appAvatar')?.valid}>
                Save New App
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-1"
                  />
                )}
              </Button>
            </form>
          </Card>

          {currentApp && (
            <footer className="d-flex justify-content-center">
              <Link
                className="mt-4 btn btn-primary"
                route={routes.appDashboard}
                params={{ appId: currentApp?.id }}>
                Back To App{' '}
                <img
                  alt="Back to App"
                  className="ms-1"
                  width="15"
                  src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z'/%3E%3C/svg%3E"
                />
              </Link>
            </footer>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default withStore(NewApplication)
