import React, { useRef } from 'react'
import cn from 'classnames'
import { Input } from 'reactstrap'
import { uniqueId } from 'lodash'
import CheckboxIcon from './CheckboxIcon'

import style from './Checkbox.scss'

interface IProps {
  checked?: boolean
  className?: string
  label?: string | React.ReactNode
  onChange?: (value: boolean) => void
  wrapperClass?: string
  invalid?: boolean
  disabled?: boolean
  id?: string
  [key: string]: any
}

export default function Checkbox({
  checked,
  onChange,
  className,
  wrapperClass,
  label,
  invalid,
  disabled,
  id,
  ...otherProps
}: IProps): React.ReactElement {
  const uniqueID = useRef(uniqueId())
  return (
    <div className={cn(wrapperClass)}>
      <Input
        id={id || uniqueID.current}
        type="checkbox"
        checked={checked}
        className={cn(style.checkboxInput)}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.checked)
          }
        }}
        disabled={disabled}
        {...otherProps}
      />
      <label
        htmlFor={id || uniqueID.current}
        className={cn(
          style.checkboxContainer,
          className,
          { 'error-input': invalid },
          { 'disabled-opacity': disabled },
          'mb-0'
        )}>
        <CheckboxIcon />
        {label && <span className={style.label}>{label}</span>}
      </label>
    </div>
  )
}
