import { ReactElement } from 'react'
import { Controller, useForm } from 'react-hook-form'
import cn from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import {
  getFormServerSideErrors,
  hookFormValidation,
  showGeneralError
} from '~/utils/validations'
import { changePassword } from '~/api/auth'
import Link from '~/components/Link'
import PasswordInput from '~/components/forms/PasswordInput'
import { NotificationType, showNotification } from '~/utils/Notification'
import SuccessMessage from '../components/SuccessMessage'

const ERRORS_DICT = {
  resetPasswordToken: {
    'Missing parameter reset_password_token': 'Invalid token'
  }
}

export type FORM_FIELDS = {
  password: string
  passwordConfirmation: string
}

const ResetPassword = (): ReactElement => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const {
    watch,
    control,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful }
  } = useForm<FORM_FIELDS>()

  const onSubmit = async (data: FORM_FIELDS) => {
    try {
      const { path } = await changePassword({
        ...data,
        resetPasswordToken: token
      })
      showNotification(
        'Password changed successfully',
        NotificationType.SUCCESS
      )
      history.push(path)
    } catch (error) {
      if (error.body.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <>
      {!isSubmitSuccessful && (
        <form
          onSubmit={(e) => {
            clearErrors()
            handleSubmit(onSubmit)(e)
          }}>
          <h2 className="">Change your password</h2>
          <Controller
            control={control}
            name="password"
            rules={hookFormValidation.password}
            render={({ field: { onChange } }) => (
              <PasswordInput
                className="mt-4 pt-2"
                name="password"
                onChange={onChange}
                error={errors.password?.message}
                label={
                  <div className="d-flex justify-content-between">
                    <span>New Password</span>
                    <Link tabIndex={-1} route={{ path: '/admins/sign_in' }}>
                      Back to Sign In
                    </Link>
                  </div>
                }
              />
            )}
          />
          <Controller
            control={control}
            name="passwordConfirmation"
            rules={{
              ...hookFormValidation.passwordConfirmation,
              validate: (value: string) => {
                return (
                  watch('password') === value ||
                  'Password Confirmation must match password'
                )
              }
            }}
            render={({ field: { onChange } }) => (
              <PasswordInput
                className="mt-4 pt-2"
                name="passwordConfirmation"
                onChange={onChange}
                error={errors.passwordConfirmation?.message}
                label="New Password Confirmation"
              />
            )}
          />
          <div className="form-general-error mt-3">{errors.token?.message}</div>
          <Button
            disabled={isSubmitting}
            type="submit"
            color="body"
            className={cn(
              'd-flex align-items-center mt-4 justify-content-center w-100 btn--hover',
              { disabled: !isValid }
            )}>
            Change my password{' '}
            {isSubmitting && (
              <FontAwesomeIcon
                className="ms-2"
                icon={faSpinner}
                spin
                size="xs"
              />
            )}
          </Button>
        </form>
      )}
      {isSubmitSuccessful && (
        <SuccessMessage>
          You will receive an email with instructions on how to reset your
          password in a few minutes.
        </SuccessMessage>
      )}
    </>
  )
}

export default ResetPassword
