import { ReactElement } from 'react'
import TimeAgo from 'react-timeago'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import VitalsTile from './VitalsTile'
import SeparatorLine from '~/components/SeparatorLine'
import CircleIcon from '~/components/CircleIcon'
import useUserDetails from '~/pages/Users/UserDetails/useUserDetails'
import { useStore } from '~/dataStore'

const UserVitals = (): ReactElement => {
  const { data: user, isLoading } = useUserDetails()
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()

  const SERVICES = [
    {
      id: '1',
      label: 'Pulsate Push',
      enabled: user?.optOutPreferences.push === false,
      tooltip:
        'Is the user is opted into Pulsate push materials (Default is set to on).'
    },
    ...(featureFlags.email
      ? [
          {
            id: '2',
            label: 'Pulsate Email',
            enabled: user?.optOutPreferences.email === false,
            tooltip:
              'Is the user is opted into Pulsate email materials (Default is set to on).'
          }
        ]
      : []),
    ...(featureFlags.sms
      ? [
          {
            id: '3',
            label: 'Pulsate SMS',
            enabled: user?.optOutPreferences.sms === false,
            tooltip:
              'Is the user is opted into Pulsate sms materials (Default is set to on).'
          }
        ]
      : []),
    {
      id: '4',
      label: 'Pulsate Locations',
      enabled: user?.locationTrackingEnabled,
      tooltip:
        'Does the user allow Pulsate access to Location (Default is set on on).'
    }
  ]

  return (
    <>
      <h3 className="mb-0">Customer Vitals</h3>
      <SeparatorLine spaceTop={3} />
      <div className="d-flex justify-content-between">
        <div>
          <VitalsTile
            subTitle="App Interaction"
            title={
              !isLoading ? (
                <TimeAgo date={user?.updatedAt} key={user?.updatedAt} />
              ) : (
                <Skeleton width={150} />
              )
            }
          />
          <div className="mt-3">
            <VitalsTile
              title={
                !isLoading ? (
                  moment(user?.createdAt).format('Do MMMM YYYY')
                ) : (
                  <Skeleton width={150} />
                )
              }
              subTitle="Signed Up"
            />
          </div>
        </div>
        <div>
          {SERVICES.map((item) => (
            <div
              className="d-flex align-items-center flex-row mb-4"
              key={item.id}>
              {isLoading ? (
                <Skeleton width={26} height={26} circle />
              ) : (
                <CircleIcon
                  tooltip={item.tooltip}
                  bg={item.enabled ? 'info' : 'danger'}
                />
              )}
              <span className="ps-2">{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UserVitals
