import React from 'react'
import {
  faClone,
  faFileDownload,
  faPen,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'
import { useStore } from '~/dataStore'
import IconButton from '~/components/IconButton'
import routes from '~/routes'
import { useExportUsers, useInvalidateSegments } from '../useSegmentsList'
import { ISegmentListItem } from '../SegmentsList.interface'
import { duplicateSegment } from '~/api/segments'

const SegmentsRowActions = ({
  row
}: {
  row: ISegmentListItem
}): React.ReactElement => {
  const invalidateSegments = useInvalidateSegments()
  const { mutate: exportUsers } = useExportUsers()
  const {
    app: { currentApp }
  } = useStore()
  const {
    ui: { showModal },
    app: {
      currentApp: { id: appId },
      closeCurtain
    },
    goTo
  } = useStore()

  const {
    id,
    actions: { edit, export: canExport, delete: canDelete, view, duplicate }
  } = row

  async function onDuplicateClick() {
    try {
      closeCurtain()
      const newSegment = await duplicateSegment(appId, id)

      goTo(
        'segmentsBuilder',
        {
          appId,
          segmentId: newSegment.id
        },
        {}
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="row-actions">
      {/* //! Mobx routers Link doesn't pass all props including ID */}
      <span className="float-start">
        <IconButton
          url={routes.segmentsBuilder}
          params={{
            segmentId: row.id
          }}
          icon={faPen}
          disabled={!edit}
          className="me-1"
          dataTestId="editSegmentBtn"
          id={`edit-${id}`}
        />
        <UncontrolledTooltip placement="bottom" target={`edit-${id}`}>
          Edit
        </UncontrolledTooltip>
      </span>
      <IconButton
        url={routes.segmentUsersList}
        params={{ appId: currentApp.id, segmentId: id }}
        icon={faUser}
        disabled={!view}
        className="me-1"
        dataTestId="viewSegmentUsersBtn"
        id={`view-${id}`}
      />
      <UncontrolledTooltip placement="bottom" target={`view-${id}`}>
        View Users
      </UncontrolledTooltip>
      <IconButton
        onClick={onDuplicateClick}
        icon={faClone}
        className="me-1"
        disabled={!duplicate}
        dataTestId="duplicateSegmentBtn"
        id={`duplicate-${id}`}
      />
      <UncontrolledTooltip placement="bottom" target={`duplicate-${id}`}>
        Duplicate
      </UncontrolledTooltip>
      <IconButton
        icon={faFileDownload}
        className="me-1"
        dataTestId="exportSegmentBtn"
        disabled={!canExport}
        onClick={() => exportUsers({ id })}
        id={`download-${id}`}
      />
      <UncontrolledTooltip placement="bottom" target={`download-${id}`}>
        Download csv
      </UncontrolledTooltip>
      <IconButton
        onClick={() => {
          showModal('deleteElementsModal', {
            elements: [row],
            url: row.batchActionUrls?.delete,
            onSuccess: invalidateSegments
          })
        }}
        icon={faTrash}
        dataTestId="deleteSegmentBtn"
        disabled={!canDelete}
        className=""
        id={`delete-${id}`}
      />
      <UncontrolledTooltip placement="bottom" target={`delete-${id}`}>
        Delete
      </UncontrolledTooltip>
    </div>
  )
}

export default SegmentsRowActions
