import cn from 'classnames'
import { lowerCase } from 'lodash'
import Input from '~/components/forms/Input'
import { withStore } from '~/dataStore'
import { MatchTypes, timeFrame } from '../../SegmentBuilder.interface'
import styles from '../../SegmentBuilder.scss'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchTypeOptions: { name: string; value: MatchTypes }[] = [
  {
    name: 'less than',
    value: 'less'
  }
]

function RuleDateAgoLastActive({ rule }: IProps): React.ReactElement {
  return (
    <>
      <span>is</span>
      <RuleDropdown
        selected={
          matchTypeOptions.find(
            (option) => option.value === rule.value.matchType
          )?.name
        }
        options={matchTypeOptions}
        action={(value) => {
          rule.edit({
            matchType: value,
            matchEndValue: undefined
          })
        }}
      />
      <Input
        name={`rule-numeric-matchValue-${rule.id}`}
        className={cn('mb-0', styles.ruleInput)}
        value={rule.value.matchValue}
        onChange={(e) => rule.edit({ matchValue: e.target.value })}
        errorTooltip={rule.errors.matchValue}
        invalid={!!rule.errors.matchValue}
      />

      <RuleDropdown
        selected={lowerCase(rule.value.timeFrame)}
        options={timeFrame.map((frame) => ({ name: frame, value: frame }))}
        action={(value) => rule.edit({ timeFrame: value })}
      />

      <span>ago</span>
    </>
  )
}

export default withStore(RuleDateAgoLastActive)
