import React from 'react'
import { useStore, withStore } from '~/dataStore'
import AccountSettingsPersonal from './Sections/AccountSettingsPersonal'
import AccountSettingsSecurity from './Sections/AccountSettingsSecurity'
import AccountSettingsApiSection from './Sections/AccountSettingsApiSection'
import AccountSettingsAdminsSection from './Sections/AccountSettingsAdminsSection'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import SectionBody from './SectionBody'

import styles from './AccountSettings.scss'

const AccountSettings = () => {
  const {
    app: { currentAdmin, setCurrentAdmin, currentApp }
  } = useStore()

  return (
    <div className="d-flex justify-content-center pb-5">
      <div className={styles.pageWrapper}>
        <Header className="mb-4">
          <HeaderTitle>Account Settings</HeaderTitle>
        </Header>
        <SectionBody name="Personal">
          <AccountSettingsPersonal
            appId={currentApp.id}
            onSubmit={setCurrentAdmin}
            {...currentAdmin}
          />
        </SectionBody>
        <SectionBody name="Security">
          <AccountSettingsSecurity appId={currentApp.id} />
        </SectionBody>
        <SectionBody name="API Connection">
          <AccountSettingsApiSection
            token={currentAdmin.adminAccessToken}
            onSuccess={setCurrentAdmin}
            appId={currentApp.id}
          />
        </SectionBody>
        <div className="mt-5">
          <AccountSettingsAdminsSection appId={currentApp.id} />
        </div>
      </div>
    </div>
  )
}

export default withStore(AccountSettings)
