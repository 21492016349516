import React from 'react'
import { ModalBody, ModalFooter, Button } from 'reactstrap'
import pluralize from 'pluralize'
import cn from 'classnames'
import Modal from '~/components/modals/Modal'
import styles from './CSVErrorsModal.scss'
import SeparatorLine from '~/components/SeparatorLine'
import DownloadLink from '~/components/DownloadLink'
import { useStore } from '~/dataStore'

const ModalContent = ({
  closeModal,
  errors = {},
  csvDownloadLink,
  segmentName,
  customTag,
  segmentFormActive
}: {
  closeModal?: () => void
  errors?: Record<string, string[]>
  csvDownloadLink?: string
  segmentName?: string
  customTag?: string
  segmentFormActive?: boolean
}) => {
  const {
    ui: { showModal }
  } = useStore()
  const errorsCount = Object.keys(errors).length
  return (
    <>
      <ModalBody className="px-0 pt-0 pb-2">
        <p className="text-muted">
          Exactly {errorsCount} {pluralize('error', errorsCount)} in following{' '}
          {pluralize('row', errorsCount)} should be corrected
        </p>
        <ul className={cn(styles.errorsContainer)}>
          {Object.keys(errors)?.map((row) => (
            <li key={row} className={styles.errorItem}>
              Row - {row}
              <ul>
                {errors[row].map((error) => (
                  <li key={error} className={styles.errorItem}>
                    {error}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <SeparatorLine dashed />
        <div className="d-flex items-center justify-content-between align-items-center">
          <p className="text-muted mb-0 w-50">
            Download the .CSV file containing a list of errors assigned to
            specific rows
          </p>
          {csvDownloadLink && (
            <DownloadLink
              classes="btn--wide"
              iconClasses="text-danger"
              url={csvDownloadLink}>
              Download Errors
            </DownloadLink>
          )}
        </div>
        <SeparatorLine dashed />
      </ModalBody>
      <ModalFooter className="float-end d-block w-50 text-end pt-0">
        <p className="text-muted mt-0">
          In order to add/update users please upload corrected versions in a
          separate .CSV file
        </p>
        <div className="mt-2">
          <Button color="" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (closeModal) closeModal()
              showModal('usersCSVUploadModal', {
                segmentName,
                customTag,
                segmentFormActive
              })
            }}
            color="primary">
            Upload .CSV File
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

const CSVErrorsModal = (): React.ReactElement => {
  return (
    <Modal
      id="CSVErrorsModal"
      size="md"
      renderHeader={() => 'Detected Errors'}
      render={(
        close: () => void,
        params: {
          segmentName?: string
          customTag?: string
          segmentFormActive?: boolean
        }
      ) => <ModalContent closeModal={close} {...params} />}
    />
  )
}

export default CSVErrorsModal
