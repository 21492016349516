import { UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import { PropsWithChildren } from 'react'
import SegmentDetails from '~/pages/Segment/SegmentDetails'
import { useStore, withStore } from '~/dataStore'
import Modal from '~/components/modals/Modal'
import { ID } from '~/common.interface'

interface IProps {
  segmentId: ID
  withIcon?: boolean
}

function SegmentDetailsBtnWithModal({
  segmentId,
  withIcon = true,
  children
}: PropsWithChildren<IProps>): React.ReactElement {
  const {
    ui: { showModal }
  } = useStore()
  return (
    <div
      role="button"
      tabIndex={0}
      className="d-flex align-items-center"
      onKeyPress={() => showModal(`segment-${segmentId}-modal`)}
      onClick={(e) => {
        e.stopPropagation()
        showModal(`segment-${segmentId}-modal`)
      }}>
      {children}
      {withIcon && (
        <>
          <i
            id={`segment-details-btn-${segmentId}`}
            className="py-2 px-2 d-flex align-items-center">
            <FontAwesomeIcon icon={faUser} size="xs" />
          </i>

          <UncontrolledTooltip
            placement="top"
            target={`segment-details-btn-${segmentId}`}>
            View Users
          </UncontrolledTooltip>
        </>
      )}
      <Modal
        id={`segment-${segmentId}-modal`}
        size="xl"
        render={() => (
          <div className={cn('spark-root', 'bg-white')}>
            <SegmentDetails segmentId={segmentId} />
          </div>
        )}
      />
    </div>
  )
}

export default withStore(SegmentDetailsBtnWithModal)
