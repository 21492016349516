import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import DeleteAppModal from './DeleteAppModal'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import routes from '~/routes'
import Modal from '~/components/modals/Modal/Modal'
import useAppManager from './useAppManager'
import { withStore } from '~/dataStore'
import TableNew from '~/components/Table/TableNew'
import tableColumns from './ApplicationsTable/tableColumns'

import styles from './AppManager.scss'

const AppManager = (): React.ReactElement => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useAppManager()

  return (
    <>
      <div className={styles.pageContainer}>
        <div className="d-flex align-items-center ms-auto">
          <Header className="mb-0">
            <HeaderTitle className="mb-0">App Manager</HeaderTitle>
          </Header>
          <div className="d-flex ms-auto">
            <Link
              to={routes.newApp.path}
              color=""
              className="btn--hover btn--wide btn btn-primary btn-lg">
              <span>
                <FontAwesomeIcon icon={faPlus} size="xs" className="me-1" />{' '}
                Create New App
              </span>
            </Link>
          </div>
        </div>
        <div className="card p-3 mt-3">
          <TableNew
            onLoadMore={() => fetchNextPage()}
            striped
            hasMore={hasNextPage}
            isLoading={isLoading}
            isLoadingMore={isFetchingNextPage}
            columns={tableColumns}
            rows={data?.pages || []}
          />
        </div>
      </div>
      <Modal
        id="confirmDeleteAppModal"
        size="md"
        renderHeader={() => <span className="color-danger">Delete App</span>}
        render={(close: () => void, params) => (
          <DeleteAppModal
            closeModal={close}
            application={params?.application}
          />
        )}
      />
    </>
  )
}

export default withStore(AppManager)
