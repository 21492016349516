import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Collapse } from 'reactstrap'
import style from '~/components/Sidebar/style.scss'

interface IProps {
  name: string
  badgeColor: string
  badgeText: string
  icon: string
  customIcon: string
  isOpen: boolean
  children: React.ReactNode | React.ReactNode[]
  onClick: () => void
  to: string
  dataTestId: string
}

const SidebarCategory = ({
  name,
  badgeColor,
  badgeText,
  icon,
  customIcon,
  isOpen,
  children,
  onClick,
  to,
  dataTestId
}: IProps): React.ReactElement => {
  return (
    <li
      data-testid={dataTestId}
      className={cn('sidebar-item sidebar-item-category')}>
      <span
        data-toggle="collapse"
        className={cn('sidebar-link', { collapsed: !isOpen })}
        onClick={onClick}
        aria-expanded={isOpen ? 'true' : 'false'}>
        {customIcon && <img className="me-2" src={customIcon} alt={name} />}
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className={cn('align-middle', 'me-2')}
          />
        )}{' '}
        <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge
            color={badgeColor}
            size={18}
            pill
            className={cn('ms-3', style.badge)}>
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul id="item" className={cn('sidebar-dropdown', 'list-unstyled')}>
          {children}
        </ul>
      </Collapse>
    </li>
  )
}

export default SidebarCategory
