import React, { useEffect } from 'react'
import {
  faObjectUngroup,
  faObjectGroup,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import reject from 'lodash/reject'
import { Button, Spinner } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import CustomDropdown from '~/components/CustomDropdown'
import SearchInput from '~/components/forms/SearchInput'
import MultiSelect from '~/components/forms/MultiSelect'
import GroupsOption from '~/components/Table/components/GroupsOption'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { IGeneralBulkActions } from '~/common.interface'
import { useInvalidateSegments } from '~/pages/Segment/SegmentsList/useSegmentsList'
import {
  ISegmentListItem,
  QUERY_PARAMS_DEFAULTS
} from '../SegmentsList.interface'

interface IProps {
  selectedSegments: Map<string, ISegmentListItem>
  clearSelectedSegments: () => void
  batchActionUrls: IGeneralBulkActions & { resume: string; pause: string }
  searchQuery: string
  handleSearch: (searchQuery: string) => void
  queryParams: typeof QUERY_PARAMS_DEFAULTS
  setSelectedGroup: (group: IGroup) => void
  isLoading: boolean
}

const SegmentsToolbar = ({
  batchActionUrls,
  searchQuery,
  handleSearch,
  queryParams,
  setSelectedGroup,
  selectedSegments,
  clearSelectedSegments,
  isLoading
}: IProps) => {
  const {
    groups: { groups },
    ui: { showModal },
    app: { appDetails }
  } = useStore()

  const invalidateSegments = useInvalidateSegments()
  const clearSelectedAndInvalidate = () => {
    invalidateSegments()
    clearSelectedSegments()
  }

  const batchActions = [
    {
      name: 'Add to a Group',
      icon: faObjectGroup,
      action: () => showModal('addElementsToGroup')
    },
    {
      name: 'Remove from a Group',
      icon: faObjectUngroup,
      action: () => showModal('removeElementsFromGroup'),
      hidden:
        Array.from(selectedSegments, ([key, value]) => value).flatMap(
          (c) => c.groups
        ).length === 0
    },
    {
      name: 'Delete Segments',
      icon: faTrash,
      action: () =>
        showModal('deleteElementsModal', {
          elements: Array.from(selectedSegments, ([, value]) => value),
          url: batchActionUrls?.delete,
          onSuccess: clearSelectedAndInvalidate
        })
    }
  ]

  const handleFilterGroups = (values: string[]) => {
    queryParams.groupIds = values.join(',')
  }

  const handleTypes = (values: string[]) => {
    queryParams.types = values.join(',')
  }

  useEffect(() => {
    if (
      appDetails.id &&
      !appDetails.featureFlags.journeys &&
      queryParams.types
    ) {
      handleTypes([])
    }
  }, [appDetails.featureFlags.journeys, appDetails.id])

  const groupFilterOptions = groups.map((g) => ({
    name: (
      <GroupsOption
        {...g}
        setEditGroup={(id: string) => {
          setSelectedGroup(id)
          showModal('editGroupModal')
        }}
        setDeleteGroup={(id: string) => {
          setSelectedGroup(id)
          showModal('removeGroupModal')
        }}
      />
    ),
    value: g.id
  }))

  return (
    <div className="d-flex align-items-center p-3">
      <CustomDropdown
        options={batchActions}
        selectedItems={selectedSegments}
        visible={selectedSegments.size > 0}
        className="me-3"
      />
      <SearchInput
        placeholder="Search for a segment"
        initialValue={searchQuery}
        onChange={handleSearch}
        className="me-3 flex-0-1"
      />
      <MultiSelect
        title="Groups"
        selectedOptions={reject(
          queryParams.groupIds?.split(','),
          (s) => s === ''
        )}
        onSelect={handleFilterGroups}
        resetOptionName="Show All"
        options={groupFilterOptions}
        className="me-3"
        bottomChildren={
          <div className="d-flex">
            <Button
              onClick={() => showModal('createGroupModal')}
              color="primary"
              data-testid="createGroupNameItem"
              className="mx-3 my-2 w-100 text-nowrap">
              + Create Group Name
            </Button>
          </div>
        }
      />
      {appDetails.featureFlags.journeys && (
        <MultiSelect
          title="Types"
          selectedOptions={reject(
            queryParams.types?.split(','),
            (s) => s === ''
          )}
          onSelect={handleTypes}
          resetOptionName="Show All"
          options={[
            { name: 'Manual', value: 'manual' },
            { name: 'Auto (Journeys)', value: 'auto' }
          ]}
        />
      )}
      {isLoading && <Spinner color="primary" type="grow" className="ms-auto" />}
    </div>
  )
}

export default withStore(SegmentsToolbar)
