import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { matchPath, useLocation } from 'react-router-dom'

import { Badge } from 'reactstrap'
import Link from '~/components/Link'

import style from './style.scss'

interface IProps {
  name: string
  badgeColor: string
  badgeText: string
  icon: string
  customIcon: string
  isOpen: boolean
  newTab: boolean
  children: React.ReactNode | React.ReactNode[]
  onClick: () => void
  to: { route: string; params: any } | string
}

const SidebarCategory = ({
  name,
  badgeColor,
  customIcon,
  badgeText,
  icon,
  isOpen,
  onClick,
  newTab,
  to
}: IProps): React.ReactElement => {
  const location = useLocation()

  const getSidebarItemClass = (path: { route: { path: string } }) => {
    return matchPath(location?.pathname, {
      path: path.route.path
    })
      ? 'active'
      : null
  }

  return (
    <li className={cn('sidebar-item', getSidebarItemClass(to))}>
      <Link
        navigationLink={!!to?.route}
        route={to.route || to}
        params={to.params}
        {...(newTab ? { target: '_blank' } : {})}
        className={cn(style.simpleCategory)}>
        <span
          className={cn('sidebar-link', {
            collapsed: !isOpen
          })}
          onClick={onClick}
          aria-expanded={isOpen ? 'true' : 'false'}>
          {customIcon && <img className="me-2" src={customIcon} alt={name} />}
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className={cn('align-middle', 'me-2')}
            />
          )}{' '}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge
              color={badgeColor}
              size={18}
              pill
              className={cn('ms-3', style.badge)}>
              {badgeText}
            </Badge>
          ) : null}
        </span>
      </Link>
    </li>
  )
}

export default SidebarCategory
