import { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { useUserSegments } from '~/pages/Users/UserDetails/useUserDetails'

const UserSegmentsSection = (): ReactElement => {
  const { data: segments, isLoading } = useUserSegments()
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between pt-3">
        <CardTitle tag="h3" className="mb-0">
          Included in Segments
        </CardTitle>
      </CardHeader>
      <CardBody className="pb-2">
        <div className="d-flex flex-wrap">
          {isLoading && (
            <>
              <Skeleton className="me-3" width={100} />
              <Skeleton className="me-3" width={100} />
              <Skeleton className="me-3" width={100} />
              <Skeleton width={100} />
            </>
          )}
          {!isLoading &&
            segments?.data.map((segment) => (
              <p
                className="me-4 mb-3 d-flex align-items-center"
                key={segment.id}>
                <FontAwesomeIcon icon={faFilter} className="me-1" />{' '}
                {segment.name}
              </p>
            ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default UserSegmentsSection
