import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import { uniqueId } from 'lodash'
import { TBadgeVariations } from '~/common.interface'

import styles from './CircleIcon.scss'

interface IProps {
  bg?: TBadgeVariations
  icon?: IconDefinition
  className?: string
  tooltip?: string
}

const CircleIcon = ({
  bg,
  icon,
  className,
  tooltip
}: IProps): React.ReactElement => {
  const id = useRef(uniqueId('circle-icon'))
  return (
    <i
      id={id.current}
      className={cn(
        'rounded-circle p-1 d-flex align-items-center justify-content-center',
        styles.iconWrapper,
        { [`bg-${bg}`]: bg },
        className
      )}>
      {icon && <FontAwesomeIcon icon={icon} className="text-white" size="xs" />}
      {tooltip && (
        <UncontrolledTooltip placement="top" target={id.current}>
          {tooltip}
        </UncontrolledTooltip>
      )}
    </i>
  )
}

export default CircleIcon
