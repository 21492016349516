import { useHistory, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { ReactElement } from 'react'
import Input from '~/components/forms/Input'
import {
  getFormServerSideErrors,
  hookFormValidation,
  showGeneralError
} from '~/utils/validations'
import { ERRORS_DICT } from '~/pages/Auth/NewAccountRegistration/NewAccountRegistration.interface'
import { registerNewAccount } from '~/api/admins'
import styles from './NewAdminRegistration.scss'
import PasswordInput from '~/components/forms/PasswordInput'

interface FormFields {
  name: string
  username: string
  password: string
  passwordConfirmation: string
}

const NewAdminRegistration = (): ReactElement => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const {
    control,
    setError,
    watch,
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting }
  } = useForm<FormFields>()

  const saveForm = async (data: FormFields) => {
    try {
      const { path } = await registerNewAccount({
        ...data,
        inviteToken: token
      })
      history.push(path)
    } catch (error) {
      if (error.body.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <>
      <h2 className="mb-3 pb-1">New Account Registration</h2>
      <div className={cn('mt-4 pt-2', styles.formWrapper)}>
        <form onSubmit={handleSubmit(saveForm)}>
          <Input
            validation={{
              required: {
                value: true,
                message: 'Full Name is missing'
              }
            }}
            errorTooltip={errors?.name?.message}
            className={cn({ 'form-group--error': errors.name })}
            name="name"
            placeholder="Enter Full Name"
            label="Full Name"
            register={register}
          />
          <Input
            name="username"
            validation={{
              required: {
                value: true,
                message: 'Username is missing'
              }
            }}
            errorTooltip={errors?.username?.message}
            className={cn('my-3', { 'form-group--error': errors.username })}
            placeholder="Enter Username"
            label="Username"
            register={register}
          />
          <Controller
            control={control}
            name="password"
            rules={hookFormValidation.password}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <PasswordInput
                name="password"
                className="mt-3"
                onChange={onChange}
                error={error?.message}
                label="Password"
              />
            )}
          />
          <Controller
            control={control}
            name="passwordConfirmation"
            rules={{
              required: {
                value: true,
                message: 'Password Confirmation is missing'
              },
              validate: (value: string) => {
                return (
                  watch('password') === value ||
                  'Password Confirmation must match password'
                )
              }
            }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <PasswordInput
                name="passwordConfirmation"
                className="mt-3"
                onChange={onChange}
                error={error?.message}
                label="Password Confirmation"
              />
            )}
          />
          <div className="form-general-error mt-3">{errors.token?.message}</div>
          <Button
            disabled={isSubmitting}
            type="submit"
            color="body"
            className={cn(
              'd-flex align-items-center justify-content-center mt-4 w-100 btn--hover',
              { disabled: !isValid }
            )}>
            Create account{' '}
            {isSubmitting && (
              <FontAwesomeIcon
                className="ms-2"
                icon={faSpinner}
                spin
                size="xs"
              />
            )}
          </Button>
        </form>
      </div>
    </>
  )
}

export default NewAdminRegistration
