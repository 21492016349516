import React from 'react'
import { Container } from 'reactstrap'
import { withStore } from '~/dataStore'
import UsersList from './UsersList'

const Users = () => {
  return (
    <Container fluid>
      <UsersList withSegmentFiltering />
    </Container>
  )
}

export default withStore(Users)
