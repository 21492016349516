import React from 'react'
import { Container } from 'reactstrap'
import { useParams } from 'react-router-dom'
import UsersList from '~/pages/Users/UsersList'

const SegmentUsersList = (): React.ReactElement => {
  const { segmentId } = useParams<{ segmentId: string }>()
  return (
    <Container fluid>
      <UsersList segmentId={segmentId} />
    </Container>
  )
}

export default SegmentUsersList
