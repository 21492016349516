import { ReactElement } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import cn from 'classnames'
import useUserDetails from '~/pages/Users/UserDetails/useUserDetails'
import TableNew from '~/components/Table/TableNew'
import CircleIcon from '~/components/CircleIcon'
import { IDevice } from '~/pages/Users/Users.interface'
import { breakLine, col, colWide } from '~/components/Table/Table.scss'

const UserDevicesSection = (): ReactElement => {
  const { data: user, isLoading } = useUserDetails()
  const columns = [
    {
      fieldName: '',
      displayName: '',
      render: ({
        row: { type, osVersion }
      }: {
        row: IDevice
      }): React.ReactElement => (
        <div className={cn(col, colWide, breakLine)}>
          {type} {osVersion}
        </div>
      )
    },
    {
      fieldName: 'appVersion',
      displayName: 'App Version'
    },
    {
      fieldName: 'sdkVersion',
      displayName: 'SDK Version'
    },
    {
      fieldName: 'timezone',
      displayName: 'Timezone'
    },
    {
      fieldName: 'guid',
      displayName: 'GUID'
    },
    {
      fieldName: 'pushPermission',
      displayName: 'Push Notifications',
      render: ({
        row: { pushPermission, deviceDataNotAvailable }
      }: {
        row: IDevice
      }): React.ReactElement => (
        <>
          {deviceDataNotAvailable ? (
            <CircleIcon
              bg="gray"
              tooltip="If SDK < 2.16 on Android device, option not available"
            />
          ) : (
            <CircleIcon
              bg={pushPermission ? 'info' : 'danger'}
              tooltip="Is the user is allowing system Push Permissions on this device"
            />
          )}
        </>
      )
    },
    {
      fieldName: 'locationPermission',
      displayName: 'Location Permissions',
      render: ({
        row: { locationPermission, deviceDataNotAvailable }
      }: {
        row: IDevice
      }): React.ReactElement => (
        <>
          {deviceDataNotAvailable ? (
            <CircleIcon
              bg="gray"
              tooltip="If SDK < 2.16 on Android device, option not available"
            />
          ) : (
            <CircleIcon
              bg={locationPermission ? 'info' : 'danger'}
              tooltip="Is the user allowing system Location permissions on this device"
            />
          )}
        </>
      )
    },
    {
      fieldName: 'active',
      displayName: 'Active',
      render: ({ row: { active } }: { row: IDevice }): React.ReactElement => (
        <CircleIcon
          bg={active ? 'info' : 'danger'}
          tooltip="Is the user currently logged in and active within Pulsate on this device"
        />
      )
    },
    {
      fieldName: 'installed',
      displayName: 'Installed',
      render: ({
        row: { uninstalled }
      }: {
        row: IDevice
      }): React.ReactElement => (
        <CircleIcon
          bg={uninstalled ? 'danger' : 'info'}
          tooltip="Does the user have the app installed on this device"
        />
      )
    }
  ]
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between pb-0 pt-3">
        <CardTitle tag="h3" className="mb-0">
          Device information
        </CardTitle>
      </CardHeader>
      <CardBody className="pt-3 pb-3">
        <TableNew
          noResultsMessage="No Device connected"
          columns={columns}
          isLoading={isLoading}
          rows={user?.devices || []}
        />
      </CardBody>
    </Card>
  )
}

export default UserDevicesSection
