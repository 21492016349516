import { ReactElement, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import { Button } from 'reactstrap'
import Input from '~/components/forms/Input'
import {
  getFormServerSideErrors,
  hookFormValidation
} from '~/utils/validations'
import {
  ERRORS_DICT,
  STEP1_FORM_FIELDS,
  TStep1FormFields
} from '~/pages/Auth/NewAccountRegistration/NewAccountRegistration.interface'
import PasswordInput from '~/components/forms/PasswordInput'

interface IProps {
  saveFormState: (data: TStep1FormFields) => void
  serverSideErrors: unknown
  isActive: boolean
}

const Step1 = ({
  saveFormState,
  serverSideErrors,
  isActive
}: IProps): ReactElement => {
  const {
    control,
    clearErrors,
    setError,
    watch,
    handleSubmit,
    register,
    formState: { errors, isValid }
  } = useForm<TStep1FormFields>({ mode: 'onBlur' })

  useEffect(() => {
    if (serverSideErrors) {
      getFormServerSideErrors(serverSideErrors, ERRORS_DICT).forEach((er) => {
        if (STEP1_FORM_FIELDS.includes(er.name)) {
          setError(er.name, { type: 'server', message: er.message })
        }
      })
    }
  }, [serverSideErrors, setError])

  return (
    <form
      onSubmit={(e) => {
        clearErrors()
        handleSubmit(saveFormState)(e)
      }}>
      <Input
        validation={{
          required: {
            value: true,
            message: 'Full Name is missing'
          }
        }}
        errorTooltip={errors?.name?.message}
        className={cn({ 'form-group--error': errors.name })}
        name="name"
        tabIndex={isActive ? 0 : -1}
        placeholder="Enter Full Name"
        label="Full Name"
        tooltip=""
        register={register}
      />

      <Input
        name="username"
        validation={{
          required: {
            value: true,
            message: 'Username is missing'
          }
        }}
        tabIndex={isActive ? 0 : -1}
        errorTooltip={errors?.username?.message}
        className={cn('my-3', { 'form-group--error': errors.username })}
        placeholder="Enter Username"
        label="Username"
        register={register}
      />
      <Input
        name="email"
        placeholder="Enter Email"
        tabIndex={isActive ? 0 : -1}
        className={cn('mt-3', { 'form-group--error': errors.email })}
        errorTooltip={errors?.email?.message}
        validation={hookFormValidation.email}
        label="Email"
        register={register}
      />
      <Controller
        control={control}
        name="password"
        rules={hookFormValidation.password}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <PasswordInput
            name="password"
            className="mt-3"
            onChange={onChange}
            error={error?.message}
            label="Password"
          />
        )}
      />
      <Controller
        control={control}
        name="passwordConfirmation"
        rules={{
          required: hookFormValidation.passwordConfirmation.required,
          validate: (value: string) => {
            return (
              watch('password') === value ||
              'Password Confirmation must match password'
            )
          }
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <PasswordInput
            name="passwordConfirmation"
            className="mt-3"
            onChange={onChange}
            error={error?.message}
            label="Password Confirmation"
          />
        )}
      />
      <Button
        color="body"
        type="submit"
        className={cn('d-block mt-4 w-100 btn--hover', { disabled: !isValid })}>
        Next
      </Button>
    </form>
  )
}

export default Step1
