import { ReactElement, ReactNode } from 'react'

import styles from './VitalsTile.scss'

interface IProps {
  title: string | ReactNode
  subTitle: string
}

const VitalsTile = ({ title, subTitle }: IProps): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <h4 className="fw-normal">{title}</h4>
      <p className="fw-medium text-13">{subTitle}</p>
    </div>
  )
}

export default VitalsTile
