import React, { useState } from 'react'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip, FormGroup } from 'reactstrap'
import radialGeofenceIcon from '~/pages/Geofences/components/radialGeofenceIcon.svg'
import polygonGeofenceIcon from '~/pages/Geofences/components/polygonGeofenceIcon.svg'
import Input from '~/components/forms/Input'
import IconButton from '~/components/IconButton'
import { IGeofence } from '~/pages/Geofences/Geofences.interface'
import { handleLongName } from '~/utils/string'
import { withStore } from '~/dataStore'

interface IProps {
  selectGeofence: (id: string) => void
  onDeleteRow: (geofence: IGeofence) => void
  editGeofence: (geofence: IGeofence) => void
  row: IGeofence
}

const GeofenceItem = ({
  selectGeofence,
  onDeleteRow,
  editGeofence,
  row
}: IProps) => {
  const hasError = !!row.error?.name?.length
  const [edit, setEdit] = useState<boolean>(hasError)
  const [name, setName] = useState(row.name)
  const { groups } = row

  const geofenceGroups = () => {
    let label
    switch (groups.length) {
      case 0:
        label = 'No group assigned'
        break
      case 1:
        label = handleLongName(groups[0].name, 22)
        break
      default:
        label = `${handleLongName(
          groups.map((group) => group.name).join(', '),
          22
        )} (+${groups.length - 1})`
    }

    return label
  }

  async function updateName() {
    if (name !== row.name) {
      try {
        await editGeofence({ ...row, name })
        setEdit(false)
      } catch (error) {
        setEdit(true)
      }
    } else if (!hasError) setEdit(false)
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="geofences-list-item"
      data-geofence-id={row.id}
      onClick={() => {
        if (hasError) return
        selectGeofence(row.id)
      }}>
      <>
        {!edit && (
          <div className="items-center p-0">
            <div className="ms-1">
              <div className="d-flex">
                <img
                  src={
                    row.shape === 'circle'
                      ? radialGeofenceIcon
                      : polygonGeofenceIcon
                  }
                  alt={row.shape}
                  width={16}
                  className="geofences-list-item__shape me-2"
                />
                <p
                  className="geofences-list-item__name mb-0"
                  id={`geofence-${row.id}-name`}
                  data-testid="geofence-name">
                  {name ? (
                    handleLongName(name, 19)
                  ) : (
                    <p className="error-label mb-0">Name is missing</p>
                  )}
                </p>
              </div>
              <p
                className="geofences-list-item__groups mb-0"
                id={`geofence-${row.id}-groups`}>
                {geofenceGroups()}
              </p>
            </div>
          </div>
        )}
        {edit && (
          <form
            data-testid="geofence-item-edit-form"
            className="geofences-list-item__form"
            onSubmit={(e) => {
              e.preventDefault()
              updateName()
            }}>
            <FormGroup
              className={`${
                row.error?.name?.length ? 'form-group--error' : ''
              } mb-0`}>
              <Input
                autoFocus={!row.error}
                type="text"
                bsSize="xs"
                name={`input-${row.id}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={updateName}
                className="w-100 mb-0 font-base"
                data-testid="geofence-item-edit-form-input"
              />
              {row.error?.name?.length && (
                <p className="geofences-list-item__form-error error-label mt-2 mb-0">
                  {row.error?.name[0]}
                </p>
              )}
            </FormGroup>
          </form>
        )}
      </>
      <div className="geofences-list-item__actions me-1">
        <IconButton
          onClick={() => setEdit((prev) => !prev)}
          icon={faPen}
          url=""
          className="me-1"
          id={`edit-${row.id}`}
          dataTestId="edit-geofence"
        />
        <UncontrolledTooltip placement="top" target={`edit-${row.id}`}>
          Edit
        </UncontrolledTooltip>

        <IconButton
          url=""
          onClick={() => onDeleteRow(row)}
          icon={faTrash}
          className=""
          id={`delete-${row.id}`}
          dataTestId="delete-geofence"
        />
        <UncontrolledTooltip placement="top" target={`delete-${row.id}`}>
          Delete
        </UncontrolledTooltip>
        {!edit && groups.length > 0 && (
          <UncontrolledTooltip
            placement="top"
            target={`geofence-${row.id}-groups`}>
            {groups.map((group) => group.name).join(', ')}
          </UncontrolledTooltip>
        )}
        {!edit && name && name.length > 19 && (
          <UncontrolledTooltip
            placement="top"
            target={`geofence-${row.id}-name`}>
            {name}
          </UncontrolledTooltip>
        )}
      </div>
    </div>
  )
}

export default withStore(GeofenceItem)
