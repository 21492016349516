import cn from 'classnames'
import { Button } from 'reactstrap'
import React, { PropsWithChildren } from 'react'
import styles from './SlideInSidebar.scss'

interface IProps {
  isOpen: boolean
  close: () => void
  size: 'md' | 'wide'
  className?: string
}

const SlideInSidebar = ({
  isOpen,
  children,
  close,
  size,
  className
}: PropsWithChildren<IProps>) => {
  return (
    <aside
      className={cn(
        styles.sidebar,
        {
          [styles.sidebarActive]: isOpen,
          [styles.sidebarMd]: size === 'md',
          [styles.sidebarWide]: size === 'wide'
        },
        className
      )}>
      {isOpen && (
        <div className="h-100 position-relative">
          <Button
            color=""
            className={cn(styles.sidebarClose, 'close')}
            onClick={close}
          />
          {children}
        </div>
      )}
    </aside>
  )
}

export default SlideInSidebar
