import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCopyright } from '@fortawesome/free-regular-svg-icons'

import moment from 'moment'
import style from './style.scss'

const Footer = (): React.ReactElement => {
  return (
    <footer className={style.footer}>
      <FontAwesomeIcon icon={faCopyright} fixedWidth />
      {moment().year()} Pulsate <br />
      All rights reserved
    </footer>
  )
}

export default Footer
