import cn from 'classnames'
import { lowerCase } from 'lodash'
import Input from '~/components/forms/Input'
import { withStore } from '~/dataStore'
import { MatchTypes, timeFrame } from '../../SegmentBuilder.interface'
import styles from '../../SegmentBuilder.scss'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchTypeOptions: {
  name: string
  value: MatchTypes
}[] = [
  {
    name: 'less than',
    value: 'less'
  },
  {
    name: 'more than',
    value: 'more'
  },
  {
    name: 'between',
    value: 'between'
  },
  {
    name: 'equal to',
    value: 'equal'
  },
  {
    name: 'not equal to',
    value: 'not_equal'
  },
  {
    name: 'anniversary today',
    value: 'anniversary_today'
  }
]

function RuleDateAgo({ rule }: IProps): React.ReactElement {
  return (
    <>
      <span>is</span>
      <RuleDropdown
        selected={
          matchTypeOptions.find(
            (option) => option.value === rule.value.matchType
          )?.name
        }
        options={matchTypeOptions}
        action={(value) => {
          rule.edit({
            matchType: value,
            matchEndValue: value === 'between' ? '' : undefined
          })
        }}
      />
      {rule.value.matchType !== 'anniversary_today' && (
        <>
          <Input
            name={`rule-numeric-matchValue-${rule.id}`}
            className={cn('mb-0', styles.ruleInput)}
            value={rule.value.matchValue}
            onChange={(e) => rule.edit({ matchValue: e.target.value })}
            errorTooltip={rule.errors.matchValue}
            invalid={!!rule.errors.matchValue}
          />
          {rule.value.matchType === 'between' && (
            <>
              <span>and</span>
              <Input
                name={`rule-numeric-matchEndValue-${rule.id}`}
                className={cn('mb-0', styles.ruleInput)}
                value={rule.value.matchEndValue}
                onChange={(e) => rule.edit({ matchEndValue: e.target.value })}
                errorTooltip={rule.errors.matchEndValue}
                invalid={!!rule.errors.matchEndValue}
              />
            </>
          )}

          <RuleDropdown
            selected={lowerCase(rule.value.timeFrame)}
            options={timeFrame.map((frame) => ({ name: frame, value: frame }))}
            action={(value) => rule.edit({ timeFrame: value })}
          />

          <span>ago</span>
        </>
      )}
    </>
  )
}

export default withStore(RuleDateAgo)
