import { ReactElement } from 'react'
import {
  Card,
  CardBody,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormFeedback
} from 'reactstrap'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAddUserNote, useUserDetails } from '../useUserDetails'
import DebouncedInput from '~/components/forms/DebouncedInput'

const UserNotes = (): ReactElement => {
  const { data: user } = useUserDetails()
  const {
    mutateAsync: addNote,
    isLoading,
    isSuccess,
    isError
  } = useAddUserNote()

  return (
    <Card>
      <CardBody className="p-0">
        <UncontrolledAccordion defaultOpen="1">
          <AccordionItem>
            <AccordionHeader targetId="1" className="mb-0">
              <h3 className="fw-medium">Notes</h3>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <div className="mt-n2">
                <DebouncedInput
                  debounceTime={500}
                  initialValue={user?.note}
                  placeholder="Add useful notes on invidual users in this section."
                  type="textarea"
                  onChange={(value: string) => addNote({ content: value })}
                />
                <FormFeedback className="d-block mt-2" valid>
                  {isLoading && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="xs"
                      className=""
                    />
                  )}
                  {isSuccess && 'Saved'}
                </FormFeedback>
                {isError && (
                  <FormFeedback className="d-block mt-2">
                    Failed to save note, please try again later.
                  </FormFeedback>
                )}
              </div>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </CardBody>
    </Card>
  )
}

export default UserNotes
