import { ReactElement } from 'react'
import { Button } from 'reactstrap'
import styles from '../UserDetails.scss'
import {
  useSubscribeUser,
  useUserDetails
} from '~/pages/Users/UserDetails/useUserDetails'

const UserStatusOverlay = (): ReactElement | null | undefined => {
  const { mutateAsync: subscribeUser } = useSubscribeUser()
  const { data: user } = useUserDetails()

  if (user?.status.deleted) return null

  if (user?.status.unsubscribed) {
    return (
      <div className={styles.resubscribeOverlay}>
        <div className="text-center">
          <Button color="primary" onClick={() => subscribeUser()}>
            Resubscribe
          </Button>
          <p className="text-white mt-4">This user has been unsubscribed</p>
        </div>
      </div>
    )
  }

  if (user?.status.uninstalled) {
    return (
      <div className={styles.resubscribeOverlay}>
        <p className="text-white mt-4">This user has been uninstalled</p>
      </div>
    )
  }

  return null
}

export default UserStatusOverlay
