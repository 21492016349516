import React from 'react'
import UsersList from '~/pages/Users/UsersList'

export default function SegmentDetails({
  segmentId
}: {
  segmentId: string
}): React.ReactElement {
  return <UsersList segmentId={segmentId} inModal />
}
