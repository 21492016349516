import React from 'react'
import cn from 'classnames'
import { Progress } from 'reactstrap'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import Pill from '~/components/Pill'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'

import styles from './StatusPill.scss'

interface IProps<T> {
  mini?: boolean
  status: T
  height?: number | string
  width?: number | string
  children?: React.ReactElement[] | React.ReactElement
  className?: string
  progress?: number
}

const statusColors: {
  [x in Lowercase<CampaignStatus | JourneyStatus>]: {
    color: string
    outline?: boolean
  }
} = {
  active: { color: 'info' },
  paused: { color: 'warning' },
  stopped: { color: 'warning' },
  draft: { color: 'gray' },
  scheduled: { color: 'warning', outline: true },
  delivered: { color: 'primary' },
  preparing: { color: 'gray' }
}

const StatusPillWithProgress = ({
  status,
  progress
}: {
  status: CampaignStatus | JourneyStatus
  progress: number
}) => {
  return (
    <div className={styles.progressWrapper}>
      <span className={styles.progressLabel}>{status}</span>
      <Progress
        className={styles.progress}
        value={progress}
        color="info"
        animated={false}
      />
    </div>
  )
}

const StatusPill = <T extends CampaignStatus | JourneyStatus>({
  mini,
  status,
  height,
  width = 125,
  children,
  className,
  progress
}: IProps<T>): React.ReactElement => {
  return (
    <Pill
      {...statusColors[status?.toLowerCase() as Lowercase<T>]}
      style={{ height: `${height}px`, width: `${width}px` }}
      className={cn('position-relative', className, {
        [styles.withProgress]: progress && progress > 0,
        'px-4 py-2': !mini && !progress
      })}>
      {progress && progress > 0 ? (
        <StatusPillWithProgress progress={progress} status={status} />
      ) : (
        children || status
      )}
    </Pill>
  )
}

export default StatusPill
