import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button } from 'reactstrap'
import TableHeader from '~/components/Table/TableHeader'
import { ID } from '~/common.interface'
import ExportUsersBtn from './ExportUsersBtn'
import { useStore } from '~/dataStore'
import HeaderTitle from '~/components/HeaderTitle'
import Header from '~/components/Header'

interface IProps {
  segmentName: string | undefined
  segmentId: ID | undefined
  totalUsers: number | undefined
  exportUsersUrl: string | undefined
}

const UsersHeader = ({ totalUsers }: { totalUsers: IProps['totalUsers'] }) => (
  <>
    Users <span className="text-muted">({totalUsers})</span>
  </>
)

const UsersListHeader = ({
  segmentName,
  segmentId,
  totalUsers,
  exportUsersUrl
}: IProps): React.ReactElement => {
  const {
    ui: { showModal },
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()

  if (!segmentId) {
    return (
      <Header className="d-flex justify-content-between align-items-center mt-0 pt-3 mb-4">
        <HeaderTitle className="mb-0">
          <UsersHeader totalUsers={totalUsers} />
        </HeaderTitle>
        <div className="d-flex">
          <ExportUsersBtn exportUrl={exportUsersUrl} />
          {featureFlags?.csvUsersUpload && (
            <Button
              color="primary"
              className="btn--wide btn--hover ms-3"
              onClick={() => {
                showModal('usersCSVUploadModal')
              }}
              size="lg">
              Add/Update Users
            </Button>
          )}
        </div>
      </Header>
    )
  }

  if (segmentName) {
    return (
      <Header className="d-flex justify-content-between align-items-center mt-0 pt-3 mb-4">
        <HeaderTitle className="text-break pe-3">
          {segmentName} - <UsersHeader totalUsers={totalUsers} />
        </HeaderTitle>
        <ExportUsersBtn exportUrl={exportUsersUrl} />
      </Header>
    )
  }
  return (
    <TableHeader>
      <Skeleton width={200} />
    </TableHeader>
  )
}

export default UsersListHeader
