import React from 'react'
import cn from 'classnames'

interface IProps {
  className?: string
  children: React.ReactNode
  tag?: keyof JSX.IntrinsicElements
}

const HeaderTitle = ({
  children,
  className,
  tag: Tag = 'h1',
  ...rest
}: IProps): React.ReactElement => (
  <Tag className={cn('header-title', className)} {...rest}>
    {children}
  </Tag>
)

export default HeaderTitle
