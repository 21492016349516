import cn from 'classnames'
import Input from '~/components/forms/Input'
import { withStore } from '~/dataStore'
import { MatchTypes } from '../../SegmentBuilder.interface'
import styles from '../../SegmentBuilder.scss'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchTypeOptions: { name: string; value: MatchTypes }[] = [
  {
    name: 'less than',
    value: 'less'
  },
  {
    name: 'more than',
    value: 'more'
  },
  {
    name: 'between',
    value: 'between'
  },
  {
    name: 'equal to',
    value: 'equal'
  },
  {
    name: 'not equal to',
    value: 'not_equal'
  }
]

function RuleNumeric({ rule }: IProps): React.ReactElement {
  return (
    <>
      <span>is</span>
      <RuleDropdown
        selected={
          matchTypeOptions.find(
            (option) => option.value === rule.value.matchType
          )?.name
        }
        options={matchTypeOptions}
        action={(value) => {
          rule.edit({
            matchType: value,
            matchEndValue: value === 'between' ? '' : undefined
          })
        }}
      />
      <Input
        className={cn('mb-0', styles.ruleInput)}
        value={rule.value.matchValue}
        onChange={(e) => rule.edit({ matchValue: e.target.value })}
        name={`rule-numeric-matchValue-${rule.id}`}
        errorTooltip={rule.errors.matchValue}
        invalid={!!rule.errors.matchValue}
      />
      {rule.value.matchType === 'between' && (
        <>
          <span>and</span>
          <Input
            className={cn('mb-0', styles.ruleInput)}
            value={rule.value.matchEndValue}
            onChange={(e) => rule.edit({ matchEndValue: e.target.value })}
            name={`rule-numeric-matchEndValue-${rule.id}`}
            errorTooltip={rule.errors.matchEndValue}
            invalid={!!rule.errors.matchEndValue}
          />
        </>
      )}
    </>
  )
}

export default withStore(RuleNumeric)
