import React from 'react'
import { ModalBody, ModalFooter, Button } from 'reactstrap'
import pluralize from 'pluralize'
import Modal from '~/components/modals/Modal'
import styles from './SegmentCSVErrorsModal.scss'
import SeparatorLine from '~/components/SeparatorLine'
import DownloadLink from '~/components/DownloadLink'
import { useStore } from '~/dataStore'

const ModalContent = ({
  closeModal,
  identifiers = [],
  customTag,
  segmentName,
  csvDownloadLink
}: {
  closeModal: () => void
  identifiers: Array<string>
  customTag?: string
  segmentName?: string
  csvDownloadLink?: string
}) => {
  const {
    ui: { showModal, hideModal }
  } = useStore()
  return (
    <>
      <ModalBody className="px-0 pt-0 pb-2">
        <p className="text-muted">
          Exactly {pluralize('alias', identifiers.length)} in following rows
          should be corrected
          <br /> The most common problems are: typo in alias, alias was never
          added to Pulsate
        </p>
        <ul className={styles.errorsContainer}>
          {identifiers.map((row) => (
            <li key={row} className={styles.errorItem}>
              {row}
            </li>
          ))}
        </ul>
        <SeparatorLine dashed />
        <div className="d-flex items-center justify-content-between align-items-center">
          <p className="text-muted mb-0">
            Download the list with the invalid identifiers marked in the second
            column
          </p>
          <DownloadLink
            classes="btn--wide"
            iconClasses="text-danger"
            url={csvDownloadLink}>
            Download Errors
          </DownloadLink>
        </div>
        <SeparatorLine dashed />
      </ModalBody>
      <ModalFooter className="float-end d-block w-50 text-end pt-0">
        <p className="text-muted mt-0">
          In order to add this segment please upload corrected versions in a
          separate .CSV file
        </p>
        <div className="mt-2">
          <Button color="" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              hideModal('segmentCSVErrorsModal')
              showModal('createSegmentFromCSVModal', {
                segmentName,
                customTag
              })
            }}
            color="primary">
            Upload .CSV File
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

const SegmentCSVErrorsModal = (): React.ReactElement => {
  return (
    <Modal
      id="segmentCSVErrorsModal"
      size="lg"
      renderHeader={() => 'Detected Errors'}
      render={(
        close: () => void,
        params: {
          identifiers: Array<string>
          segmentName?: string
          customTag?: string
          csvDownloadLink?: string
        }
      ) => (
        <ModalContent
          closeModal={close}
          identifiers={params?.identifiers}
          segmentName={params?.segmentName}
          customTag={params?.customTag}
          csvDownloadLink={params?.csvDownloadLink}
        />
      )}
    />
  )
}

export default SegmentCSVErrorsModal
