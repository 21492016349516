import { ReactElement } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useUserDetails } from '../useUserDetails'
import SeparatorLine from '~/components/SeparatorLine'
import { withStore, useStore } from '~/dataStore'

const UserPersonalData = (): ReactElement => {
  const { data: user, isLoading } = useUserDetails()
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()

  return (
    <div>
      <h3 className="mb-0">Personal Information</h3>
      <SeparatorLine spaceTop={3} />
      {!isLoading ? <h4 className="mb-4">{user?.fullName}</h4> : <Skeleton />}
      <table className="my-2 table w-85">
        {!isLoading && (
          <tbody>
            {user?.email && (
              <tr>
                <th>Email</th>
                <td>{user.email}</td>
              </tr>
            )}
            {user?.alias && (
              <tr>
                <th>Alias</th>
                <td>{user?.alias}</td>
              </tr>
            )}
            {user?.currentLocation && featureFlags.geofences && (
              <tr>
                <th>Last Known Location</th>
                <td>
                  {user.currentCity},{user.currentCountry}
                </td>
              </tr>
            )}
            {!!user?.age && (
              <tr>
                <th>Age</th>
                <td>{user.age}</td>
              </tr>
            )}
            {user?.gender && (
              <tr>
                <th>Gender</th>
                <td>{user.gender}</td>
              </tr>
            )}
            {user?.phone && (
              <tr>
                <th className="border-0">Phone</th>
                <td className="border-0">{user.phone}</td>
              </tr>
            )}
          </tbody>
        )}
        {isLoading && (
          <tbody>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

export default withStore(UserPersonalData)
