import Skeleton from 'react-loading-skeleton'
import { Col, Row } from 'reactstrap'
import React from 'react'
import SummaryField from '~/pages/Campaign/CampaignReports/UI/SummaryField'
import Columns from '~/components/Columns'
import StatusPill from '~/components/Filters/StatusPill'
import HeaderTitle from '~/components/HeaderTitle'
import StatCard from '~/components/reports/StatCard'
import { JourneyStatus } from '../../../Journeys.interface'
import JourneyTemplateIcon from '../../../UI/JourneyTemplateIcon/JourneyTemplateIcon'
import { ISummary } from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import blankJourneyTemplate from '../../../NewJourney/blankJourney.json'
import SummaryTarget from '~/components/reports/SummaryTarget'
import { ID } from '~/common.interface'

interface IProps {
  report?: {
    name: string
    status: JourneyStatus
    journeyTemplateSource: { name: string; smallImage: string; id: ID }
  }
  summary: ISummary[]
}

export default function JourneyOverview({
  report,
  summary
}: IProps): React.ReactElement {
  return (
    <StatCard>
      <Row>
        <Col xl="9">
          <div className="d-flex align-items-center mb-5">
            {report ? (
              <>
                <StatusPill
                  className="me-3 status-pill"
                  status={report.status}
                  mini
                  width="auto"
                />
                <HeaderTitle className="mb-0">{report.name}</HeaderTitle>
              </>
            ) : (
              <Skeleton width="200px" />
            )}
          </div>
          <Columns columnCount="3" className="mb-5" border>
            {summary.map((field) => (
              <SummaryField
                key={field.label}
                label={field.label}
                content={field.content}
                className={field.className}
              />
            ))}
            {report?.target && (
              <SummaryField
                label="Target"
                content={<SummaryTarget target={report.target} />}
              />
            )}
          </Columns>
        </Col>
        <Col
          xl="3"
          className="d-flex justify-content-center align-items-center flex-column">
          {report && (
            <>
              <JourneyTemplateIcon
                src={
                  report.journeyTemplateSource.smallImage ||
                  blankJourneyTemplate.smallImage
                }
              />
              {report.journeyTemplateSource.id && (
                <>
                  <span className="fw-medium color-subheader mb-0 mt-2">
                    Journey Template
                  </span>
                  <p className="text-18 fw-medium my-2">
                    {report.journeyTemplateSource.name}
                  </p>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </StatCard>
  )
}
