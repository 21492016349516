import {
  Autocomplete,
  Circle,
  GoogleMap,
  useJsApiLoader
} from '@react-google-maps/api'
import cn from 'classnames'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { useRef, useState } from 'react'
import Input from '~/components/forms/Input'
import { MAP_LIBRARIES, CIRCLE_OPTIONS } from '~/pages/Geofences/consts'
import { useStore, withStore } from '~/dataStore'
import mapPin from '~/components/icons/map_pin.svg'
import styles from '../../SegmentBuilder.scss'
import SegmentRule from '../SegmentRule.model'
import RangeSlider from '~/components/RangeSlider'
import '~/pages/Geofences/components/GeofencesMap/GeofencesMap.scss'

interface IProps {
  rule: SegmentRule
}

const mapCenter = {
  lat: 53.349322,
  lng: -6.260254
}

function RuleLocation({ rule }: IProps): React.ReactElement {
  const {
    app: { googleMapsApiKey }
  } = useStore()

  const [map, setMap] = useState<google.maps.Map | undefined>()
  const [placesAutoComplete, setPlacesAutoComplete] =
    useState<google.maps.places.Autocomplete>()

  const circleRef = useRef<Circle>(null)

  const [radius, setRadius] = useState<number>(
    rule.eventResource.radius || 1000
  )

  const goToPlace = () => {
    if (map && placesAutoComplete !== null) {
      const place = placesAutoComplete?.getPlace()
      if (place?.geometry) {
        map.fitBounds(place.geometry.viewport)
        map.setCenter(place.geometry.location)
      }

      const location = placesAutoComplete?.getPlace().geometry?.location
      rule.setEventResource({
        ...rule.eventResource,
        location: [location?.lat(), location.lng()]
      })
      map.setZoom(12)
    }
  }

  const onCircleDragEnd = () => {
    if (circleRef.current !== null) {
      rule.setEventResource({
        ...rule.eventResource,
        location: [
          circleRef.current.state.circle.getCenter().lat(),
          circleRef.current.state.circle.getCenter().lng()
        ]
      })

      map?.panTo(
        new google.maps.LatLng(
          circleRef.current.state.circle.getCenter().lat(),
          circleRef.current.state.circle.getCenter().lng()
        )
      )
    }
  }

  function changeRadius(value: number): void {
    setRadius(value)

    rule.edit({ matchValue: value.toString() })
    rule.setEventResource({
      ...rule.eventResource,
      radius: value
    })
  }

  const onLoad = function callback(map) {
    setMap(map)
  }

  const onUnmount = function callback() {
    setMap(null)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: MAP_LIBRARIES,
    googleMapsApiKey
  })

  return (
    <>
      <span>is</span>
      {isLoaded && (
        <div className="places-select">
          <Autocomplete
            onLoad={(place) => setPlacesAutoComplete(place)}
            onPlaceChanged={goToPlace}>
            <Input
              type="text"
              placeholder="Search for location"
              className="mb-0"
              suffix={
                <Button color="" className="p-0" type="button">
                  <img
                    className="cursor-pointer"
                    src={mapPin}
                    alt="Locate me"
                  />
                </Button>
              }
            />
          </Autocomplete>
        </div>
      )}
      <span className="mx-2" />
      <div className={cn(styles.ruleDropdown)}>
        <RangeSlider
          min={0}
          step={1}
          max={1_000_000}
          values={[radius]}
          onChange={(values) => {
            changeRadius(values[0])
          }}
          onFinalChange={(values) => {
            changeRadius(values[0])
          }}
        />
      </div>
      <span className="ms-1" />
      <Input
        className={cn('mb-0', styles.ruleInput)}
        value={radius}
        onChange={(e) => changeRadius(e.target.value)}
        name={`rule-numeric-matchValue-${rule.id}`}
        errorTooltip={rule.errors.matchValue}
        invalid={!!rule.errors.matchValue}
      />

      <span id="meters">m</span>
      <UncontrolledTooltip placement="top" target="meters">
        meters
      </UncontrolledTooltip>

      {isLoaded && (
        <div
          className={cn(
            styles.ruleMap,
            'geofences-map d-flex align-items-stretch mt-3'
          )}>
          <GoogleMap
            options={{
              fullscreenControl: true,
              zoomControl: true,
              streetViewControl: false,
              clickableIcons: false
            }}
            mapContainerStyle={{
              width: '100%',
              height: '100%'
            }}
            center={
              {
                lat: rule.eventResource.location[0],
                lng: rule.eventResource.location[1]
              } || mapCenter
            }
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            <Circle
              ref={circleRef}
              radius={Number(radius)}
              center={
                new google.maps.LatLng(
                  rule.eventResource.location[0],
                  rule.eventResource.location[1]
                )
              }
              onDragEnd={onCircleDragEnd}
              options={{
                ...CIRCLE_OPTIONS,
                editable: rule.eventResource.isEditable,
                draggable: rule.eventResource.isDraggable,
                fillColor: rule.eventResource.color,
                strokeColor: rule.eventResource.color
              }}
            />
          </GoogleMap>
        </div>
      )}
    </>
  )
}

export default withStore(RuleLocation)
