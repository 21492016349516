import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { matchPath, useLocation } from 'react-router-dom'
import { Badge } from 'reactstrap'
import Link from '~/components/Link'

import style from './style.scss'

interface IProps {
  name: string
  badgeColor: string
  badgeText: string
  icon: string
  to: string
  newTab: boolean
}

const SidebarItem = ({
  name,
  badgeColor,
  badgeText,
  icon,
  to,
  newTab,
  dataTestId
}: IProps): React.ReactElement => {
  useLocation()

  const location = useLocation()

  const getSidebarItemClass = (path: { route: { path: string } }) => {
    return matchPath(location?.pathname, {
      path: path.route.path,
      exact: !!path.route.path.includes('dashboards')
    })
      ? 'active'
      : null
  }

  return (
    <li
      className={cn('sidebar-item', getSidebarItemClass(to))}
      data-testid={dataTestId}>
      <Link
        route={to.route || to}
        params={to.params}
        navigationLink={!!to.route}
        className={cn(
          'sidebar-link',
          getSidebarItemClass(to),
          style.sidebarItem
        )}
        {...(newTab ? { target: '_blank' } : {})}>
        {icon ? (
          <>
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className={cn('align-middle', 'me-2')}
            />{' '}
            <span className="align-middle">{name}</span>
          </>
        ) : (
          name
        )}{' '}
        {badgeColor && badgeText ? (
          <Badge
            color={badgeColor}
            size={18}
            pill
            className={cn('ms-3', style.badge)}>
            {badgeText}
          </Badge>
        ) : null}
      </Link>
    </li>
  )
}

export default SidebarItem
