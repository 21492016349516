import { get } from '~/api/v2'
import { ID } from '~/common.interface'
import { IReportListDTO } from '../CampaignReportList/CampaignReportList.interface'
import { IConversionGoalReport, IStats } from './Report.interface'

export function fetchReports(
  appId: ID,
  params: Record<string, unknown>
): Promise<IReportListDTO> {
  return get(`${appId}/reports`, params)
}

export function fetchMixStats(
  appId: string,
  campaignId: string,
  params?: Record<string, unknown>
): Promise<IStats> {
  return get(`${appId}/campaigns/${campaignId}/stats`, params)
}

export function fetchMixGoalsPerformance(
  appId: string,
  campaignId: string,
  params?: Record<string, unknown>
): Promise<{
  primaryGoal: IConversionGoalReport
  secondaryGoal: IConversionGoalReport
}> {
  return get(`${appId}/campaigns/${campaignId}/goals`, params)
}
