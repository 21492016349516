import { Button } from 'reactstrap'
import { humanDateTime } from '~/utils/time'
import Link from '~/components/Link'
import { useStore } from '~/dataStore'
import { IUser } from '~/pages/Users/Users.interface'
import routes from '~/routes'

const handleDate = (lastSessionAt: string) => {
  if (lastSessionAt) {
    return humanDateTime(lastSessionAt)
  }
  return '- -'
}

const usersListColumns = [
  {
    fieldName: 'lastName',
    displayName: 'Name',
    sortable: true,
    render: ({ row: { fullName } }: { row: IUser }) => fullName
  },
  {
    fieldName: 'alias',
    displayName: 'Alias'
  },
  {
    fieldName: 'createdAt',
    displayName: 'Signed Up',
    sortable: true,
    render: ({ row: { createdAt } }: { row: IUser }) => humanDateTime(createdAt)
  },
  {
    fieldName: 'lastSessionAt',
    displayName: 'Last Active',
    sortable: true,
    render: ({ row: { lastSessionAt } }: { row: IUser }) =>
      handleDate(lastSessionAt)
  },
  {
    fieldName: 'currentCity',
    displayName: 'Last Location',
    render: ({ row: { currentCity } }: { row: IUser }) => currentCity || '- -',
    sortable: true
  },
  {
    displayName: 'Profile',
    render: ({ row: { id } }: { row: IUser }) => {
      const {
        app: { currentApp }
      } = useStore()
      return (
        <Button
          data-testid="viewUserProfileBtn"
          tag={Link}
          route={routes.userProfile}
          params={{ appId: currentApp?.id, userId: id }}
          color="body"
          className="bg-white btn--wide btn--hover"
          outline>
          View
        </Button>
      )
    }
  }
]

export default usersListColumns
