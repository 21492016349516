import cn from 'classnames'
import { lowerCase } from 'lodash'
import { useEffect, useState } from 'react'
import { getGroups } from '~/api/groups'
import Input from '~/components/forms/Input'
import { useStore, withStore } from '~/dataStore'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { ICampaignsListItem } from '~/pages/Campaign/CampaignsList/CampaignsList.interface'
import useCampaigns from '~/pages/Campaign/CampaignsList/useCampaigns'
import {
  BE_RESOURCE_TYPE_MAP,
  EventResourceType,
  MatchTypes,
  timeFrame
} from '../../SegmentBuilder.interface'
import styles from '../../SegmentBuilder.scss'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchTypeOptions: { name: string; value: MatchTypes }[] = [
  {
    name: 'less than',
    value: 'less'
  },
  {
    name: 'more than',
    value: 'more'
  },
  {
    name: 'between',
    value: 'between'
  },
  {
    name: 'equal to',
    value: 'equal'
  },
  {
    name: 'not equal to',
    value: 'not_equal'
  }
]

const eventResourceType: { name: string; value: EventResourceType }[] = [
  {
    name: 'Campaign',
    value: BE_RESOURCE_TYPE_MAP.campaign
  },
  {
    name: 'Campaign Group',
    value: BE_RESOURCE_TYPE_MAP.campaignGroup
  }
]

function RuleLastCampaignOpen({ rule }: IProps): React.ReactElement {
  const {
    app: { currentApp },
    groups
  } = useStore()
  const { data: campaigns } = useCampaigns({
    perPage: 1000,
    filterStatuses: 'delivered,paused,active'
  })

  const [currentCollection, setCurrentCollection] = useState<
    (ICampaignsListItem | IGroup)[]
  >([])

  useEffect(() => {
    if (rule.value.eventResourceType === BE_RESOURCE_TYPE_MAP.campaign) {
      setCurrentCollection(campaigns?.pages || [])
    } else if (
      rule.value.eventResourceType === BE_RESOURCE_TYPE_MAP.campaignGroup
    ) {
      setCurrentCollection(groups.groups)
    } else {
      setCurrentCollection([])
    }
  }, [rule.value.eventResourceType, campaigns, groups.groups])

  useEffect(() => {
    if (currentCollection.length) {
      let eventResource = currentCollection[0]

      if (rule.value.eventResourceId) {
        eventResource =
          currentCollection.find(
            (elem) => elem.id === rule.value.eventResourceId
          ) || eventResource
        rule.edit({ eventResourceId: undefined })
      }

      rule.setEventResource(eventResource)
    }
  }, [currentCollection])

  useEffect(() => {
    getGroups(currentApp.id, 'campaigns').then((response) =>
      groups.setGroups(response.data)
    )
  }, [currentApp.id])

  return (
    <>
      <span>of</span>
      <RuleDropdown
        selected={
          eventResourceType.find(
            (option) => option.value === rule.value.eventResourceType
          )?.name
        }
        options={eventResourceType}
        action={(value) => {
          rule.edit({ eventResourceType: value, eventResource: undefined })
        }}
      />
      <RuleDropdown
        selected={rule.eventResource?.name || 'nothing selected'}
        options={
          currentCollection.map((item) => ({
            name: item.name,
            value: item
          })) || []
        }
        action={(value) => {
          rule.setEventResource(value)
        }}
      />

      <span>is</span>
      <RuleDropdown
        selected={
          matchTypeOptions.find(
            (option) => option.value === rule.value.matchType
          )?.name
        }
        options={matchTypeOptions}
        action={(value) => {
          rule.edit({
            matchType: value,
            matchEndValue: value === 'between' ? '' : undefined
          })
        }}
      />
      <Input
        className={cn('mb-0', styles.ruleInput)}
        value={rule.value.matchValue}
        onChange={(e) => rule.edit({ matchValue: e.target.value })}
        name={`rule-numeric-matchValue-${rule.id}`}
        errorTooltip={rule.errors.matchValue}
        invalid={!!rule.errors.matchValue}
      />
      {rule.value.matchType === 'between' && (
        <>
          <span>and</span>
          <Input
            className={cn('mb-0', styles.ruleInput)}
            value={rule.value.matchEndValue}
            onChange={(e) => rule.edit({ matchEndValue: e.target.value })}
            name={`rule-numeric-matchEndValue-${rule.id}`}
            errorTooltip={rule.errors.matchEndValue}
            invalid={!!rule.errors.matchEndValue}
          />
        </>
      )}

      <RuleDropdown
        selected={lowerCase(rule.value.timeFrame)}
        options={timeFrame.map((frame) => ({ name: frame, value: frame }))}
        action={(value) => rule.edit({ timeFrame: value })}
      />

      <span>ago</span>
    </>
  )
}

export default withStore(RuleLastCampaignOpen)
